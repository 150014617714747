import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs';
import { UserActions } from '../actions/users/user-exported-actions';
import { IUserState, UserState } from '../initials/user-initial-state';

@Injectable()
export class UserEffects {
  constructor(private actions$: Actions) { }

  UserSetAuthorizationCode$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.SET_AUTHORIZATION_CODE),
        tap((actions) => {
          let userState = this.deserializeUserState();
          userState.jwt_token = actions.jwt_token;
          userState.jwt_token_expiration_time = actions.jwt_token_expiration_time;
          userState.authorization_code = actions.authorization_code
          localStorage.setItem('user_state', JSON.stringify(userState));
        })
      ),
    { dispatch: false }
  );

  UserResetAuthToken$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.RESET_AUTHORIZATION_TOKEN),
        tap(() => {
          let userState = this.deserializeUserState();
          userState.jwt_token = ''
          userState.jwt_token_expiration_time = 0
          userState.authorization_code = ''
          userState.access_token = ''
          userState.access_token_expiration_time = 0
          localStorage.setItem('user_state', JSON.stringify(userState));
        })
      ),
    { dispatch: false }
  );

  UserSetDealerId$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.SET_DEALER_ID),
        tap((action) => {
          let userState = this.deserializeUserState();
          userState.dealerId = action.dealerId
          localStorage.setItem('user_state', JSON.stringify(userState));
        })
      ),
    { dispatch: false }
  );

  UserResetDealerId$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.RESET_DEALER_ID),
        tap(() => {
          let userState = this.deserializeUserState();
          userState.dealerId = ''
          localStorage.setItem('user_state', JSON.stringify(userState));
        })
      ),
    { dispatch: false }
  );

  UserSetAccessToken$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.SET_ACCESS_TOKEN),
        tap((actions) => {
          let userState = this.deserializeUserState();
          userState.access_token = actions.access_token;
          userState.access_token_expiration_time = actions.access_token_expiration_time;
          localStorage.setItem('user_state', JSON.stringify(userState));
        })
      ),
    { dispatch: false }
  );

  UserSetDealersInfo$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.SET_DEALERS_INFO),
        tap((actions) => {
          let userState = this.deserializeUserState();
          userState.dealersInfo = actions.dealersInfo;
          localStorage.setItem('user_state', JSON.stringify(userState));
        })
      ),
    { dispatch: false }
  );

  UserSetMachineInfo$  = createEffect(
    () => 
    this.actions$.pipe(
      ofType(UserActions.SET_MACHINES_INFO),
      tap((actions) => {
        let userState = this.deserializeUserState();
        console.log('SET MACHINE INFO')
        userState.machinesInfo = actions.machinesInfo;
        localStorage.setItem('user_state', JSON.stringify(userState));
      })
    ),
    { dispatch: false }
  );

  UserSetRegion$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.SET_REGION),
        tap((actions) => {
          let userState = this.deserializeUserState();
          userState.region = actions.region;
          localStorage.setItem('user_state', JSON.stringify(userState))
        })
      ),
    { dispatch: false }
  )

  private deserializeUserState(): IUserState {
    if (localStorage.getItem('user_state'))
      return JSON.parse(localStorage.getItem('user_state') || '') as IUserState;
    return new UserState()
  }
}
