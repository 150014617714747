import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, distinctUntilChanged, filter, fromEvent, map, repeat, skipWhile, Subscription, takeUntil } from 'rxjs';
import { AutobahnClient } from 'src/app/core/clients/autobahn.client';
import { CAR_DETAILS_ENUM, PAIRING_STEP_ENUM, STEP_ENUM, CTA_KEYS_ENUM } from 'src/app/core/models/common.model';
import { ErrorDialogService } from 'src/app/core/services/error-dialog.service';
import { MxeAnalyticsService } from 'src/app/core/services/mxe-analytics.service';
import { UiCommonService } from 'src/app/core/services/ui-common.service';
import { MxeReducers } from 'src/app/core/store/reducers';
import SwiperCore, { EffectFade, Navigation, Pagination, SwiperOptions } from 'swiper';
import { SwiperComponent } from "swiper/angular";
import { Family } from '../../core/models/get-models-service.model';
import { APP_SETTINGS_SET_APPLICATION_STEP } from 'src/app/core/store/actions/app-settings/app-settings-actions';
import { NavigationStateService } from 'src/app/core/services/navigation-state.service';
import { environment } from 'src/environments/environment';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import countries from '../../../assets/countries.json';
import availableSNI from '../../../assets/available-sni.json'
import { ICTA } from 'src/app/core/models/country-prop-service.model';

SwiperCore.use([Pagination]);
SwiperCore.use([Navigation]);
SwiperCore.use([EffectFade]);

@Component({
  selector: 'mxe-car-models-selector',
  templateUrl: './car-models-selector.component.html',
  styleUrls: ['./car-models-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarModelSelectorComponent {
  @ViewChild(SwiperComponent, { static: false }) swiper: SwiperComponent;
  selectModel: string;
  
  @Input() set activeSlideIndex(index) {
    if(this.swiper && index >= 0) {
      this.currentIndex = index
      this.swiper.swiperRef.activeIndex = index
      this.swiper.swiperRef.update()
    } 
  }
  @Input() set activeSlideImageIndex (mi: { model:string, index: number }) {
    if(mi){
      window.CI360.setActiveIndexByID(mi.model, mi.index)
    } 
  }
  @Input() set swiperScrollDelta(data) {
    if(this.swiper  && data) {
      this.swipeModelTopNavigationBar(data)
    }
  }
  @Input() isMainScreen: boolean = true
  @Input() priceStatus: boolean;
  @Input() languageId: string;
  @Input() countryCode: number;
  @Input() labels: any;
  stockUrlWithParams: SafeResourceUrl
  showStockAvailability: boolean;
  ephemeralDeviceID$: Subscription;
  disclaimerFooter: string

  private _families: Family[] = [];
  @Input() set families(families: Family[])  {
    this._families = families
    if(!this.isMainScreen) {
      if(families && families.length > 0){
        this.familyNames = families.map(f => f.familyName)
        this.createDetailsInfo()
      }
    }

  }
  get families () {
    if(!this._families) {
      return []
    }
    else return this._families
  }
  familyNames: string[] = [];
  dynamicModelName: string = '';
  familyfiltered: string[] = [];

  currentIndex: number = 0;

  length: string | any;
  width: string;
  height: string;
  trunkVolume: string;
  dataIsChanged = false;
  lowestPriceByFamily = [];

  imagesPathByFamily = [];
  staticPercentages = [];

  homeMenuIsOpen = false;
  isModalDisclaimerOpen: boolean = false;
  currency: string
  priceMask: string

  activeTrimSlideIndex: number
  activeTrimSlideImageIndex: any

  percentagesByFamily = []
  infosByFamily = []

  pagination = {
    clickable: true,
    renderBullet: (index: number, className: string) => {
      return `<span class="${className}">${this.familyNames[index]}</span>`;
    },
  };

  config: SwiperOptions = {
    slidesPerView: 1,
    spaceBetween: 30,
    centeredSlides: true,
    speed: 1000,
    navigation: true,
    effect: "fade",
    fadeEffect: {
      crossFade: true
    },
    allowTouchMove: false,
    pagination: { clickable: true },
    scrollbar: { draggable: true },
  };


  disclaimer_cta: string;
  starting_price_label: string;
  trimPrices = [];
  labels$: Subscription;
  ephemeralDeviceID: string
  mirroringStoppedByUser: boolean = false;
 
  PairingStepEnum = PAIRING_STEP_ENUM
  Step = STEP_ENUM

  private priceInfoByFamily = [];
  private touchEventSub: Subscription;
  private swiperBullet: NodeListOf<Element>;
  private swiperButtonNext: NodeListOf<Element>;
  private swiperButtonPrev: NodeListOf<Element>;

  private countryCode$: Subscription;
  private languageId$: Subscription;
  private showPrices$: Subscription;
  private families$: Subscription;
  private screenCastActive$: Subscription
  private userState$: Subscription;
  private modelTopNavigationBarEvent$: Subscription;
  private ctas$: Subscription
  
  private dealerId: string;
  private screenCastActive: boolean = false;
  private activeImageIndexSubject = new BehaviorSubject<any>(null)
  public showSni: boolean = false
  activeImageIndex: number

  constructor(
    private chg: ChangeDetectorRef,
    private store: Store<MxeReducers>,
    private autobahnClient: AutobahnClient,
    private uiCommonService: UiCommonService,
    private mxeAnalyticsSevice: MxeAnalyticsService,
    private errorDialogService: ErrorDialogService,
    private navigation: NavigationStateService,
    private sanitizer: DomSanitizer
  ) { 
    if(this.isMainScreen) {
      this.store.dispatch(APP_SETTINGS_SET_APPLICATION_STEP({applicationStep: STEP_ENUM.MODEL_SELECTOR}))
      this.activeImageIndexSubject.pipe(
        filter(value => value !== null),
        distinctUntilChanged()
      ).subscribe(value => {
        this.activeImageIndex = value
        let carModelViewId = `${this.families[this.currentIndex].model.toLowerCase()}-model`;
        if(this.activeImageIndex && this.isMainScreen && this.screenCastActive) {
          this.autobahnClient.setModelSelectorImageUpdate(this.ephemeralDeviceID, carModelViewId, this.activeImageIndex)
        }
      })
    }
  }


  ngOnInit(): void {
    if(this.isMainScreen) {
      this.handleSubscriptions()
      this.initAdobeTracker()
    }
  }

  private handleSubscriptions() {
    this.userState$ = this.store.select(s => s.userState.dealerId).subscribe(
      dealerId => this.dealerId = dealerId
    )
    this.countryCode$ = this.store.select(s => s.appState.countryCode).subscribe(
      countryCode => this.countryCode = +countryCode!
    )
    this.languageId$ = this.store.select(s => s.appState.languageId).subscribe(
      lang => this.languageId = lang
    )

    this.showPrices$ = this.store.select(s => s.appState.showPrices).subscribe(
      showPrices => this.priceStatus = showPrices
    )

    this.screenCastActive$ = this.store.select(s => s.appState.isScreenCastActive).subscribe(
      screenCastActive => this.screenCastActive = screenCastActive)

    this.ephemeralDeviceID$ = this.store.select(s => s.appState.ephemeralDeviceId).subscribe(
      res => this.ephemeralDeviceID = res
    )

    this.families$ = this.store.select(m => m.modelsState.families).subscribe(families => {
      if(families && families.length > 0){
        this.familyNames = families.map(f => f.familyName)
        this.families = families;
        this.createDetailsInfo()
      }
    });

    this.ctas$ = this.store.select(s => s.countryPropState.ctas).subscribe(
      ctas => {
        if(ctas && Object.keys(ctas)[0]){
          let genericCtas: ICTA[] = ctas[Object.keys(ctas)[0]]
          const searchNewInventoryCta = genericCtas.find(c => c.name === CTA_KEYS_ENUM.SEARCH_NEW_INVENTORY);
          if(searchNewInventoryCta && searchNewInventoryCta.enabled){
            this.showSni = true;
          } else {
            this.showSni = false;
          }
        }
      }
    )
  }

  ngAfterViewInit() {
    this.slideSwiperEventHandler()
    this.setModelTopNavigationBar();
    if(this.isMainScreen)
      this.initDataTracking()
  }

  ngOnDestroy() {
    if(this.touchEventSub){ 
      this.touchEventSub.unsubscribe()
    }
    if(this.countryCode$) {
      this.countryCode$.unsubscribe()
    }
    if(this.languageId$) {
      this.languageId$.unsubscribe()
    }
    if(this.showPrices$) {
      this.showPrices$.unsubscribe()
    }
    if(this.families$) {
      this.families$.unsubscribe()
    }
    if(this.labels$) {
      this.labels$.unsubscribe()
    }
    if(this.ephemeralDeviceID$) {
      this.ephemeralDeviceID$?.unsubscribe()
    }
    if(this.screenCastActive$) {
      this.screenCastActive$.unsubscribe()
    }
    if(this.modelTopNavigationBarEvent$) {
      this.modelTopNavigationBarEvent$.unsubscribe()
    }
    if(this.activeImageIndexSubject) {
      this.activeImageIndexSubject.unsubscribe()
    }
    if(this.userState$) {
      this.userState$?.unsubscribe();
    }
    if(this.ctas$) {
      this.ctas$.unsubscribe();
    }
  }

  onSwiper(swiper: any) {
    this.currentIndex = this.swiper.swiperRef.activeIndex;
    this.currency = this.families[this.currentIndex]?.models[0].currency
    this.priceMask = this.families[this.currentIndex]?.models[0].priceMask
    this.families.forEach(
      (family) => {
        const carModelViewId = `${family.model.toLowerCase()}-model` 
        window.CI360._viewers = window.CI360._viewers.filter(x => x.id != carModelViewId)
        window.CI360.add(carModelViewId)
      }
    )
  }

  onSlideChange() {
    this.dataIsChanged = false
    this.currentIndex = this.swiper.swiperRef.activeIndex;
    this.currency = this.families[this.currentIndex].models[0].currency
    this.priceMask = this.families[this.currentIndex].models[0].priceMask
    this.updatePaginationPosition()

    if(this.isMainScreen && this.screenCastActive) {
      const carModelViewId = `${this.families[this.currentIndex].model}-model`
      this.autobahnClient.setModelSelectorActiveIndex(this.ephemeralDeviceID, this.swiper.swiperRef.activeIndex)
      this.activeImageIndexSubject.next(window.CI360.getActiveIndexByID(carModelViewId) || 0)
      if (typeof window.adobeDataLayer !== 'undefined') {
        window.adobeDataLayer.push({
          "event": "view360Interaction",
          "data": {
            "pageInfo": {
              "vehicleName": this.familyNames[this.currentIndex].toLowerCase(),
              "vehicleBrand": "mxe"
            },
          },
        });
      }
    }
    this.chg.detectChanges();
  }

  swipePrev() {
    this.swiper.swiperRef.slidePrev();
  }

  swipeNext() {
    this.swiper.swiperRef.slideNext();
    
  }

  formatPrice(price: string, familyCommercialCode: string) {
    return this.uiCommonService.formatPrice(price, this.priceInfoByFamily[familyCommercialCode].currency, this.priceInfoByFamily[familyCommercialCode].priceMask)
  }
  /**
   * Builds the objects that will be looped in the ui to get infos and percentages
   */
  private createDetailsInfo() {
    if(this.isMainScreen) {
      this.labels$ = this.store.select(s => s.countryPropState.labels)
      .subscribe(
        (labels) => {
          this.labels = labels
          this.uiCommonService.initializeLabels(this.labels)
          this.disclaimerFooter = this.getLabel('DISCLAIMER_FOOTER_DESCR')
          this.disclaimer_cta = this.getLabel('DISCLAIMER')
          this.starting_price_label = this.getLabel('LINE_STARTING_PRICE')
          this.selectModel = this.getLabel('MXE_SELECT_MODEL_BUTTON')
          this.chg.detectChanges()
        }
      )
    }
    else {
      if(this.labels) {
        this.uiCommonService.initializeLabels(this.labels)
        this.disclaimerFooter = this.getLabel('DISCLAIMER_FOOTER_DESCR')
        this.disclaimer_cta = this.getLabel('DISCLAIMER')
        this.starting_price_label = this.getLabel('LINE_STARTING_PRICE')
        this.selectModel = this.getLabel('MXE_SELECT_MODEL_BUTTON')
        this.chg.detectChanges()
      }
    }

    this.families.forEach(family => {
      // Lowest Price
      this.priceInfoByFamily[family.familyCommercialCode] = { currency: family.models[0]?.currency, priceMask: family.models[0]?.priceMask }
      this.lowestPriceByFamily[family.familyCommercialCode] = family.models.map(m => m.price).sort(this.comparePrices)[0] || 0
      family.models.forEach(trim => {
        this.trimPrices[trim.modelCode] = trim.price
      })
      let infos: any = []

      const lengthData = family.models.map(m => m.techData.find(t => t.key === CAR_DETAILS_ENUM.LENGTH))[0]
      infos.push({ label: lengthData?.label, value: lengthData?.value })

      const heightData = family.models.map(m => m.techData.find(t => t.key === CAR_DETAILS_ENUM.HEIGHT))[0]
      infos.push({ label: heightData?.label, value: heightData?.value })

      const widthData = family.models.map(m => m.techData.find(t => t.key === CAR_DETAILS_ENUM.WIDTH))[0]
      infos.push({ label: widthData?.label, value: widthData?.value })

      const trunkData = family.models.map(m => m.techData.find(t => t.key === CAR_DETAILS_ENUM.TRUNK))[0]
      infos.push({ label: trunkData?.label, value: trunkData?.value })

      this.infosByFamily[family.familyCommercialCode] = infos

      let techInfos: any = []
      switch(family.familyCommercialCode.toLocaleLowerCase()){
        case 'g2':
          const g2tAccelerationData = family.models.map(m => m.techData.find(t => t.key === CAR_DETAILS_ENUM.ACCELERATION))[0]
          techInfos.push({ label: g2tAccelerationData?.label, value: "100%" })
    
          const g2tSpeedData = family.models.map(m => m.techData.find(t => t.key === CAR_DETAILS_ENUM.MAX_SPEED))[0]
          techInfos.push({ label: g2tSpeedData?.label, value: "100%" })
    
          const g2tPowerData = family.models.map(m => m.techData.find(t => t.key === CAR_DETAILS_ENUM.MAX_POWER))[0]
          techInfos.push({ label: g2tPowerData?.label, value: "100%" })
          break;
        case 'gt':
          const gtAccelerationData = family.models.map(m => m.techData.find(t => t.key === CAR_DETAILS_ENUM.ACCELERATION))[0]
          techInfos.push({ label: gtAccelerationData?.label, value: "75%" })
    
          const gtSpeedData = family.models.map(m => m.techData.find(t => t.key === CAR_DETAILS_ENUM.MAX_SPEED))[0]
          techInfos.push({ label: gtSpeedData?.label, value: "98%" })
    
          const gtPowerData = family.models.map(m => m.techData.find(t => t.key === CAR_DETAILS_ENUM.MAX_POWER))[0]
          techInfos.push({ label: gtPowerData?.label, value: "86%" })
          break;
        case 'gc':
            const gcAccelerationData = family.models.map(m => m.techData.find(t => t.key === CAR_DETAILS_ENUM.ACCELERATION))[0]
            techInfos.push({ label: gcAccelerationData?.label, value: "70%" })
      
            const gcSpeedData = family.models.map(m => m.techData.find(t => t.key === CAR_DETAILS_ENUM.MAX_SPEED))[0]
            techInfos.push({ label: gcSpeedData?.label, value: "97%" })
      
            const gcPowerData = family.models.map(m => m.techData.find(t => t.key === CAR_DETAILS_ENUM.MAX_POWER))[0]
            techInfos.push({ label: gcPowerData?.label, value: "86%" })
            break;
        case 'sr':
          const srAccelerationData = family.models.map(m => m.techData.find(t => t.key === CAR_DETAILS_ENUM.ACCELERATION))[0]
          techInfos.push({ label: srAccelerationData?.label, value: "95%" })
    
          const srSpeedData = family.models.map(m => m.techData.find(t => t.key === CAR_DETAILS_ENUM.MAX_SPEED))[0]
          techInfos.push({ label: srSpeedData?.label, value: "98%" })
    
          const srPowerData = family.models.map(m => m.techData.find(t => t.key === CAR_DETAILS_ENUM.MAX_POWER))[0]
          techInfos.push({ label: srPowerData?.label, value: "98%" })
          break;
        case 'gr':
          const grAccelerationData = family.models.map(m => m.techData.find(t => t.key === CAR_DETAILS_ENUM.ACCELERATION))[0]
          techInfos.push({ label: grAccelerationData?.label, value: "65%" })
    
          const grSpeedData = family.models.map(m => m.techData.find(t => t.key === CAR_DETAILS_ENUM.MAX_SPEED))[0]
          techInfos.push({ label: grSpeedData?.label, value: "88%" })
    
          const grPowerData = family.models.map(m => m.techData.find(t => t.key === CAR_DETAILS_ENUM.MAX_POWER))[0]
          techInfos.push({ label: grPowerData?.label, value: "83%" })
          break;
        case 'mc':
          const mcAccelerationData = family.models.map(m => m.techData.find(t => t.key === CAR_DETAILS_ENUM.ACCELERATION))[0]
          techInfos.push({ label: mcAccelerationData?.label, value: "95%" })
    
          const mcSpeedData = family.models.map(m => m.techData.find(t => t.key === CAR_DETAILS_ENUM.MAX_SPEED))[0]
          techInfos.push({ label: mcSpeedData?.label, value: "100%" })
    
          const mcPowerData = family.models.map(m => m.techData.find(t => t.key === CAR_DETAILS_ENUM.MAX_POWER))[0]
          techInfos.push({ label: mcPowerData?.label, value: "98%" })
          break;
        case 'gh':
          const ghAccelerationData = family.models.map(m => m.techData.find(t => t.key === CAR_DETAILS_ENUM.ACCELERATION))[0]
          techInfos.push({ label: ghAccelerationData?.label, value: "50%" })
    
          const ghSpeedData = family.models.map(m => m.techData.find(t => t.key === CAR_DETAILS_ENUM.MAX_SPEED))[0]
          techInfos.push({ label: ghSpeedData?.label, value: "88%" })
    
          const ghPowerData = family.models.map(m => m.techData.find(t => t.key === CAR_DETAILS_ENUM.MAX_POWER))[0]
          techInfos.push({ label: ghPowerData?.label, value: "67%" })
          break;
        case 'lv':
          const lvAccelerationData = family.models.map(m => m.techData.find(t => t.key === CAR_DETAILS_ENUM.ACCELERATION))[0]
          techInfos.push({ label: lvAccelerationData?.label, value: "40%" })
    
          const lvSpeedData = family.models.map(m => m.techData.find(t => t.key === CAR_DETAILS_ENUM.MAX_SPEED))[0]
          techInfos.push({ label: lvSpeedData?.label, value: "81%" })
    
          const lvPowerData = family.models.map(m => m.techData.find(t => t.key === CAR_DETAILS_ENUM.MAX_POWER))[0]
          techInfos.push({ label: lvPowerData?.label, value: "67%" })
          break;
        case 'qp':
          const qpAccelerationData = family.models.map(m => m.techData.find(t => t.key === CAR_DETAILS_ENUM.ACCELERATION))[0]
          techInfos.push({ label: qpAccelerationData?.label, value: "40%" })
    
          const qpSpeedData = family.models.map(m => m.techData.find(t => t.key === CAR_DETAILS_ENUM.MAX_SPEED))[0]
          techInfos.push({ label: qpSpeedData?.label, value: "81%" })
    
          const qpPowerData = family.models.map(m => m.techData.find(t => t.key === CAR_DETAILS_ENUM.MAX_POWER))[0]
          techInfos.push({ label: qpPowerData?.label, value: "67%" })
          break;
      }

      this.staticPercentages[family.familyCommercialCode] = techInfos
    })
  }

  onCloseClick() {
    if(this.screenCastActive) {
      this.autobahnClient.requestPairingFromSimplePage(this.ephemeralDeviceID, PAIRING_STEP_ENUM.HOME)
    }
    this.navigation.navigate(['/mxe'])
  }

  onScreenCastActiveEmitter(value: boolean){
    this.screenCastActive = value
  }

  private comparePrices(a: number, b: number): number {
    if (a > b)
      return 1
    return -1
  }

  showCarouselTrims(family: Family) {
    if(this.screenCastActive){
      this.autobahnClient.setCarTrimFamilyCode(this.ephemeralDeviceID, family)
      this.autobahnClient.setPriceStatus(this.ephemeralDeviceID, this.priceStatus)
      this.autobahnClient.setModelSelectorActiveIndex(this.ephemeralDeviceID, 0)
    }
    this.chg.detectChanges();
    this.navigation.navigate(['mxe/models',family.model])
  }

  onCloseEmitter() {
    this.updatePageInfoAfterPopupClosing()
  }
  
  getFamilyImage360BasePath(modelName: string): string {
    return this.uiCommonService.getFamilyImage360BasePath(modelName, this.countryCode)
  }

  getLabel(optId: string, defaultValue: string = '') {
    return this.uiCommonService.getLabel(optId, '', '', '', defaultValue)
  }

  openDisclaimerModal() {
    this.isModalDisclaimerOpen = true;
  }

  disclaimerModalStatusController(modalStatus: boolean) {
    this.isModalDisclaimerOpen = modalStatus;
  }

  showHomeMenu() {
    this.homeMenuIsOpen = true;
  }

  homeMenuStatusController(modalStatus) {
    this.homeMenuIsOpen = modalStatus;
    this.updatePageInfoAfterPopupClosing()
  }

  /**
   * shows 360 swiper if family is different than Ghibli and Levante
   */
  getDataImageList(familyCommercialCode: string): string {
    return '["33.png"]'
    switch(familyCommercialCode.toLocaleLowerCase()) {
      case 'lv':
      case 'gh':
      case 'qp':
        return '["33.png"]'
      default:
        return '["33.png","34.png","35.png","36.png","01.png","02.png","03.png","04.png","05.png","06.png","07.png","08.png","09.png","10.png","11.png","12.png","13.png","14.png","15.png","16.png","17.png","18.png","19.png","20.png","21.png","22.png","23.png","24.png","25.png","26.png","27.png","28.png","29.png","30.png","31.png","32.png"]'
    }
  }

  /**
   * shows bottom circle if family is different than Ghibli and Levante
   */
  showDataBottomCircle(familyCommercialCode: string): boolean {
    return false;
    if(familyCommercialCode.toLocaleLowerCase() === 'gh' || familyCommercialCode.toLocaleLowerCase() === 'lv' || familyCommercialCode.toLocaleLowerCase() === 'qp')
      return false
    return true
  }

  private updatePageInfoAfterPopupClosing(){
    window.adobeDataLayer.push({
      "event": 'updatePageName',
      "data": {
      "pageInfo":
          { 
            "pageName": "car-model-selector" 
          }
        }
      })
  }

  private slideSwiperEventHandler() {
    const element: NodeListOf<Element> = document.querySelectorAll('.container-360-wrapper.model-selector')
    if (!element) {
      console.error('no element');
      return
    }
    const touchEvent$ = fromEvent(element, 'touchstart').pipe(
      takeUntil(fromEvent(element, 'touchend').pipe(
        map((e) => {
          if (typeof window.adobeDataLayer !== 'undefined') {
            window.adobeDataLayer.push({
              "event": "view360Interaction",
              "data": {
                "pageInfo": {
                  "vehicleName": `${this.familyNames[this.currentIndex].toLowerCase()}`,
                  "vehicleBrand": "mxe"
                },
              },
            });
          }
        }),
      )),
      repeat()
    )
    this.touchEventSub = touchEvent$.subscribe()
  }

  private initDataTracking() {
    this.swiperBullet = document.querySelectorAll('div.swiper-pagination span.swiper-pagination-bullet')
    this.swiperButtonNext = document.querySelectorAll('.swiper-button-next')
    this.swiperButtonPrev = document.querySelectorAll('.swiper-button-prev')
    this.swiperBullet.forEach((element, i) => {
      element.setAttribute('data-linktracking', `sub-menu:choose-carline:${this.mxeAnalyticsSevice.normalizeStringForAnalytics(this.familyNames[i])}`);
    })
    this.swiperButtonNext.forEach(element => {
      element.setAttribute('data-linktracking', 'engagement:choose-carline:arrow-right')
    })
    this.swiperButtonPrev.forEach(element => {
      element.setAttribute('data-linktracking', 'engagement:choose-carline:arrow-left')
    })
  }

  private initAdobeTracker() {
    window.adobeDataLayer.push({
      "event": "genericPageLoad",
      "data": {
        "category": {
          "pageType": "shopping-tools"
        },
        "pageInfo": {
          "countryCode": `${this.countryCode}`,
          "language": `${this.languageId}`,
          "pageName": "car-model-selector", 
          "vehicleBrand": "mxe",
          "tier": "tier-3"
        }
      }
    });
  }

  getActualIndex(event: any){
    let carModelViewId = `${this.families[this.currentIndex].model.toLowerCase()}-model`;
    this.activeImageIndexSubject.next(window.CI360.getActiveIndexByID(carModelViewId))
  }

  private setModelTopNavigationBar() {
    const swiperPagination: Element = document.querySelectorAll('.swiper-pagination-horizontal')[0]
    let startX: number
    let endX: number
    let scrollDelta: number

    const swiperPaginationElement = (swiperPagination as HTMLElement)
    const swiperPaginationBulletsElement = document.querySelectorAll('.swiper-pagination-bullet')

    if(this.isMainScreen) {
      this.modelTopNavigationBarEvent$ = fromEvent(swiperPagination, 'touchstart').pipe(
        takeUntil(fromEvent(swiperPagination, 'touchstart').pipe(
          map((e) => {
            startX = (e.target as HTMLElement).getBoundingClientRect().left
            console.debug(`Start: ${startX}`)
          }),
        )),
        takeUntil(fromEvent(swiperPagination, 'touchend').pipe(
          map((e) => {
            endX = (e.target as HTMLElement).getBoundingClientRect().left
            console.debug(`End: ${endX}`)

            scrollDelta = endX - startX
            console.warn(`Scrolled pagination. Delta: ${scrollDelta}`)
            if(this.screenCastActive && scrollDelta !== 0) {
              this.autobahnClient.setSelectorSwiperScroll(this.ephemeralDeviceID, {scrollDelta: scrollDelta, swiperPaginationWidth: (swiperPaginationElement.childNodes[0] as HTMLElement).getBoundingClientRect().width, component: 'model'})
            }
          }),
        )),
        repeat()
      ).subscribe()
      if(swiperPaginationBulletsElement.length > 5)
      swiperPaginationElement.style.justifyContent = 'start';
    }
  }
  private swipeModelTopNavigationBar(data: any) {
    const firstItem: HTMLElement = document.querySelectorAll('.swiper-pagination-horizontal')[0].childNodes[0] as HTMLElement
    if(firstItem) {
      const actualMargin = parseInt(firstItem.style.marginLeft.replace('px', ''))
      const scrollDelta = firstItem.getBoundingClientRect().width / data['swiperPaginationWidth'] * data['scrollDelta']
      if(scrollDelta < 60){
        if(scrollDelta < 0 && scrollDelta < actualMargin) {
          firstItem.style.marginLeft = `${scrollDelta}px`
        } else if(scrollDelta > 0 && scrollDelta > actualMargin){
          firstItem.style.marginLeft = `${scrollDelta}px`
        }
      } else {
        firstItem.style.marginLeft = `60px`
      }
    }
  }
  updatePaginationPosition(): void {
    if (!this.swiper) return;
    const swiperInstance = this.swiper.swiperRef;
    const activeIndex = swiperInstance.activeIndex;
    const paginationBullets = document.querySelectorAll('.swiper-pagination-bullet');
    const paginationContainer = document.querySelector('.swiper-pagination');

    if (paginationBullets && paginationContainer) {
      const activeBullet = paginationBullets[activeIndex] as HTMLElement;
      if (activeBullet) {
        const bulletRect = activeBullet.getBoundingClientRect();
        const containerRect = paginationContainer.getBoundingClientRect();
        
        const offset = bulletRect.left - containerRect.left + bulletRect.width / 2 - containerRect.width / 2;
        paginationContainer.scrollTo({
          left: offset,
          behavior: 'smooth'
        });
      }
    }
  }

  closeInStockAvailability() {
    this.showStockAvailability = false;
  }

  openInStockAvailability() {
    let url: string = '';
    let country: string | undefined = countries.countryList.find(c => c.countryCode == this.countryCode)?.isoCode2?.toLocaleLowerCase()
    try {
      url = `${environment.maserati_instock_endpoint}/${country}/${this.languageId}/shopping-tools/new-inventory/search-results?$location&distanceto=0&cars=${this.families[this.currentIndex].familyCommercialCode}&dealer=${this.dealerId}&iframe=true&context=mxe`
      console.log(url)
      this.stockUrlWithParams = this.sanitizer.bypassSecurityTrustResourceUrl(url)
      this.showStockAvailability = true;
    } catch(error: any) {
      throw new Error(error)
    }
  }

  modelHasSNI(familyCommercialCode: string) {
    return !availableSNI.noSNIFamilies.includes(familyCommercialCode)
  }
}

