import { createAction, props } from '@ngrx/store';
import { IDealer } from 'src/app/core/models/authorization/dealer.model';
import { IMachineInfo } from 'src/app/core/models/authorization/machine.model';

export const SET_AUTHORIZATION_CODE = createAction(
  "[authorization component] SET_AUTHORIZATION_CODE",
  props<{ 
    jwt_token: string, 
    jwt_token_expiration_time: number, 
    authorization_code: string 
  }>()
)

export const RESET_AUTHORIZATION_TOKEN = createAction(
  "[AUTHENTICATION SERVICE] RESET_AUTHORIZATION_TOKEN"
)

export const SET_DEALER_ID = createAction(
  "[AUTHENTICATION SERVICE] SET_DEALER_ID",
  props<{dealerId: string}>()
)

export const RESET_DEALER_ID = createAction(
  "[AUTHENTICATION SERVICE] RESET_DEALER_ID"
)

export const MONKEY_WAY_SESSION = createAction(
  '[INIT APP] Monkey-Way sessionId retrieved',
  props<{
    monkeyWaySessionId: string,
    monkeyWayRenderId: string,
    monkeyWayConnectionKey: string
  }>()
)

export const MONKEY_WAY_CLOSE_SESSION = createAction(
  '[HOME MENU COMPONENT] Monkey-Way session closed'
)

export const SET_ACCESS_TOKEN = createAction(
  '[authorization component] SET_ACCESS_TOKEN',
  props<{
    access_token: string,
    access_token_expiration_time: number
  }>()
)

export const SET_DEALERS_INFO = createAction(
  'SET_DEALERS_INFO',
  props<{
    dealersInfo: IDealer[]
  }>()
)

export const SET_MACHINES_INFO = createAction(
  'SET_MACHINE_INFO',
  props<{
    machinesInfo: IMachineInfo[]
  }>()
)

export const SET_REGION = createAction(
  'SET_REGION',
  props<{
    region: string
  }>()
)
