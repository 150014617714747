import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { Camera } from 'src/app/core/models/unreal-available-models.model';

@Component({
  selector: 'app-camera-carousel',
  templateUrl: './camera-carousel.component.html',
  styleUrls: ['./camera-carousel.component.scss']
})
export class CameraCarouselComponent {

  @Input() set activeCamera(camera: Camera) {
    this.cameraInUse =  camera
    this.currentIndex = this.cameras.indexOf(camera)
  };
  @Input() cameras: Camera[];
  @Input() activeFamilyCommercialCode: string
  @Input() xRayIconActive : boolean
  @Input() countryCode: string
  @Output() switchCamera: EventEmitter<Camera> = new EventEmitter();
  public currentIndex: number;
  public cameraInUse: Camera


  ngOnInit(){
    this.currentIndex = this.cameras.indexOf(this.cameraInUse)
  }

  ngOnChanges(changes : SimpleChanges){
    if(changes['xRayIconActive'] && changes['xRayIconActive'].currentValue == true && !changes['xRayIconActive'].firstChange){
      if(this.cameraInUse.location == 'int'){
        this.currentIndex = 0
        this.cameraInUse = this.cameras[0]
        this.switchCamera.emit(this.cameraInUse);
      }
    }
  }

  selectCamera(index: number) {
    if(index != this.currentIndex){
      if(this.xRayIconActive && this.cameras[index].location == 'int'){
        this.currentIndex = 0
        this.cameraInUse = this.cameras[0]
        this.switchCamera.emit(this.cameraInUse);
      }
      else{
        this.currentIndex = index
        this.cameraInUse = this.cameras[index]
        this.switchCamera.emit(this.cameraInUse);
      }
    }
  }

  getCameraImage(i:number){
    if(this.activeFamilyCommercialCode?.toLocaleLowerCase() == 'g2' && (this.countryCode === '107' || this.countryCode === '115')) {
      return `../../../assets/cameras/${this.activeFamilyCommercialCode.toLocaleLowerCase()}/camera-${i}.png` 
    } else {
      return `../../../assets/cameras/${this.activeFamilyCommercialCode.toLocaleLowerCase()}/camera-${i}.png`
    }
  }
}
