import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import packageJson from '../../package.json';
import { GetCountryPropRequest, ICTA } from './core/models/country-prop-service.model';
import { GetModelsRequest } from './core/models/get-models-service.model';
import { MaseratiService } from './core/services/maserati.service';
import { AppSettingsActions } from './core/store/actions/app-settings/app-settings-exported-actions';
import { CAR_GRID_LOADED } from './core/store/actions/car-grid-actions';
import { CONFIGURATION_LOADED } from './core/store/actions/configuration-actions';
import { CTAS_LOADED, LABELS_LOADED } from './core/store/actions/country-prop-actions';
import { INTERFACE_LOADED } from './core/store/actions/interface-actions';
import { ModelActions } from './core/store/actions/models/models-exported-actions';
import { UserActions } from './core/store/actions/users/user-exported-actions';
import { AppState } from './core/store/initials/app-settings-initial-state';
import { IUserState } from './core/store/initials/user-initial-state';
import { MxeReducers } from './core/store/reducers';
import enabledCarFamilies from '../assets/enabled-car-families.json'
import { HeartbeatService } from './core/services/heartbeat.service';
import { UiCommonService } from './core/services/ui-common.service';
import { ConfigState } from './core/store/initials/temporary-configs-initial-state';
import { TemporaryConfigActions } from './core/store/actions/temporary-configs/temporary-configs-exported-actions';
import { TEMP_CONFIG_DELETE } from './core/store/actions/temporary-configs/temporary-configs-actions';
import { MonkeyWaySessionsState } from './core/store/initials/monkeyway-sessions-initial-state';
import { MonkeyWaySessionActions } from './core/store/actions/monkeyway-sessions/monkeyway-sessions-exported-actions';
import { JwtService } from './core/services/jwt.service';
import { environment } from 'src/environments/environment';
import { SET_LAST_MODEL } from './core/store/actions/models/models-actions';
import { HandledError } from './core/models/handled-error';
import { combineLatest, distinctUntilChanged, filter, map, tap } from 'rxjs';
import { NavigationStateService } from './core/services/navigation-state.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  appName: string = packageJson.name
  version: string = packageJson.version;
  showToast: boolean = false;

  toastMessage: string | null = null;
  modalMessage: string | null = null;

  closeModal(): void {
    this.modalMessage = null;
  }

  private countryCode: number | undefined
  public languageId: string
  private ctaLanguageId: string

  constructor(
    private maseratiService: MaseratiService,
    private store: Store<MxeReducers>,
    private heartbeatService: HeartbeatService,
    private uiCommonService: UiCommonService,
    private jwtService: JwtService,
    private navigation: NavigationStateService
  ) {
    this.navigation.setToastCallback((message: string) => {
      this.toastMessage = message;
      setTimeout(() => (this.toastMessage = null), 3000);
    });

    // Collega il modale
    this.navigation.setModalCallback((message: string) => {
      this.modalMessage = message;
    });
  }

  ngOnInit(): void {
    this.checkCacheVersion()
    localStorage.removeItem('expiration_time');
    console.log(`${this.appName} v.${this.version}`);
    this.loadResourcesFromLocalStorage();

    this.store.select(s => s.appState.ctaLanguageId).subscribe(
      (ctaLanguageId) => this.ctaLanguageId = ctaLanguageId
    )
    combineLatest([
      this.store.select((store) => store.modelsState.families),
      this.store.select((store) => store.appState.countryCode).pipe(
        distinctUntilChanged(),
        tap((countryCode) => this.countryCode = countryCode)
      ),
      this.store.select((store) => store.appState.languageId).pipe(
        distinctUntilChanged(),
        tap((languageId) => this.languageId = languageId)
      )
    ])
      .pipe(
        map(([families, countryCode, languageId]) => ({
          families,
          countryCode,
          languageId
        })),
        //ignore equal duplicated emissions
        distinctUntilChanged((prev, curr) =>
          prev.countryCode === curr.countryCode &&
          prev.languageId === curr.languageId &&
          JSON.stringify(prev.families) == JSON.stringify(curr.families)
        ),
        filter(({ families, countryCode, languageId }) => {
          const loadModels = (!families || families.length === 0) && !!countryCode && !!languageId
          return loadModels
        })
      ).subscribe(() => {
        console.log(`loading Models for country ${this.countryCode}`)
        this.loadModels()
      })

    this.jwtService.jwtTokenExpired.subscribe(
      (isExpired: boolean) => {
        if (isExpired) { //JWT token expired will redirect to home page or experience choice, depending if you are onCloud or onPrem
          throw new HandledError(this.uiCommonService.getLabel('MXE_AUTH_EXPIRED', '', '', '', `Your authentication has expired, click on "continue" to return to the homepage. If you were on the 3D configuration, you can find it in the "load\\restore configuration" section`))
        }
      })

    //hack giacomini 2022-06-13
    //leave it like that. For on-premises machine tests
    const machineIdValue = localStorage.getItem('dev_machine_id')
    const unrealDebugInterface = localStorage.getItem('unreal_debug_interface');
    const mockedHighlights = localStorage.getItem('mocked_highlights');
    //const localMxeDebug = localStorage.getItem('local_mxe_ip');
    const pairingDebugEnabled = localStorage.getItem('pairing_debugging')
    const newBEArchEnabled = localStorage.getItem('new_be_architecture_enabled')
    if (machineIdValue == '' || !machineIdValue) {
      localStorage.setItem('dev_machine_id', '')
    }
    if (unrealDebugInterface === '' || !unrealDebugInterface) {
      localStorage.setItem('unreal_debug_interface', 'false')
    }
    if (mockedHighlights === '' || !mockedHighlights) {
      localStorage.setItem('mocked_highlights', 'false')
    }
    // if (localMxeDebug === '' || !localMxeDebug) {
    //   localStorage.setItem('local_mxe_ip', '')
    // }
    if (pairingDebugEnabled === '' || !pairingDebugEnabled) {
      localStorage.setItem('pairing_debugging', 'true')
    }
    if (newBEArchEnabled === '' || !newBEArchEnabled) {
      localStorage.setItem('new_be_architecture_enabled', 'true')
    }


  }

  showCustomToast(): void {
    this.showToast = true;
    setTimeout(() => {
      this.showToast = false;
    }, 3000);
  }

  private checkCacheVersion() {
    const checkVersion = localStorage.getItem('cacheVersion')
    let checkVersionConverted
    if (checkVersion) {
      checkVersionConverted = new Date(checkVersion).getTime()
      const now = new Date().getTime()
      const oneDayTime = 86400000
      if ((now - checkVersionConverted) >= oneDayTime) {
        console.debug('Outdated cache version. Clearing local storage...')
        this.emptyLocalStorage()
      } else {
        console.debug('Cache version is still valid.')
      }
    } else {
      console.debug('Invalid cache version in local storage. Clearing local storage...')
      this.emptyLocalStorage()
    }
  }

  private emptyLocalStorage() {
    localStorage.removeItem('interface')
    localStorage.removeItem('menuItems')
    localStorage.removeItem('carGrid')
    localStorage.removeItem('labels')
    localStorage.removeItem('ctas')
    localStorage.removeItem('families')
    localStorage.removeItem('currentCarModel')
    localStorage.removeItem('options')
    localStorage.removeItem('optionsForAnalytics')
    localStorage.removeItem('hiddenOptions')
    localStorage.removeItem('packages')
    localStorage.removeItem('cacheVersion')
    localStorage.setItem('cacheVersion', new Date().toDateString())
  }

  //#region LocalStorage resource
  private loadResourcesFromLocalStorage() {
    this.loadAppSettingsFromLocalStorage();
    this.loadCarGridFromLocalStorage();
    this.loadConfigurationFromLocalStorage();
    this.loadCountryPropFromLocalStorage();
    this.loadInterfaceFromLocalStorage();
    this.loadModelsFromLocalStorage();
    this.loadUserStateFromLocalStorage();
    this.loadTemporaryConfigsFromLocalStorage();
    this.loadMonkeyWaySessionStateFromLocalStorage();
    this.loadLastConfigurationFromLocalStorage();
  }
  loadLastConfigurationFromLocalStorage() {
    const lastOptions = localStorage.getItem('lastOptions') ? JSON.parse(localStorage.getItem('lastOptions')!) as any : []
    const lastPackages = localStorage.getItem('lastPackages') ? JSON.parse(localStorage.getItem('lastPackages')!) as any : []
    const lastDefaultOptions = localStorage.getItem('lastDefaultOptions') ? JSON.parse(localStorage.getItem('lastDefaultOptions')!) as any : []
    const lastHiddenOptions = localStorage.getItem('lastHiddenOptions') ? JSON.parse(localStorage.getItem('lastHiddenOptions')!) as any : []
    const lastCarModel = localStorage.getItem('lastCarModel') ? JSON.parse(localStorage.getItem('lastCarModel')!) as any : []
    const lastOptionsForAnalytics = localStorage.getItem('lastOptionsForAnalytics') ? JSON.parse(localStorage.getItem('lastOptionsForAnalytics')!) as any : []
    this.store.dispatch(SET_LAST_MODEL(
      {
        lastCarModel: lastCarModel,
        lastHiddenOptions: lastHiddenOptions,
        lastOptions: lastOptions,
        lastDefaultOptions: lastDefaultOptions,
        lastPackages: lastPackages,
        lastOptionsForAnalytics: lastOptionsForAnalytics
      }))
  }

  loadMonkeyWaySessionStateFromLocalStorage() {
    const monkeyWaySessionsState = localStorage.getItem('monkeyWay_sessions')
    if (monkeyWaySessionsState && this.isJsonString(monkeyWaySessionsState)) {
      const monkeWaySessionsObj = JSON.parse(monkeyWaySessionsState) as MonkeyWaySessionsState;
      this.store.dispatch(
        MonkeyWaySessionActions.MW_SESSION_INIT({
          sessions: monkeWaySessionsObj.sessions
        })
      )
    }
  }

  loadTemporaryConfigsFromLocalStorage() {
    //delete configurations older than 14 days and initialize
    this.store.dispatch(TEMP_CONFIG_DELETE())
    const temporaryConfigState = localStorage.getItem('temporaryConfigState')
    if (temporaryConfigState && this.isJsonString(temporaryConfigState)) {
      const temporaryConfigObj = JSON.parse(temporaryConfigState) as ConfigState;
      this.store.dispatch(
        TemporaryConfigActions.TEMP_CONFIG_INIT({
          configItems: temporaryConfigObj.configItems
        })
      )
    }
  }

  loadAppSettingsFromLocalStorage() {
    const appSettings = localStorage.getItem('appSettings');
    if (appSettings && this.isJsonString(appSettings)) {
      const appSettingsObj = JSON.parse(appSettings) as AppState;

      this.store.dispatch(
        AppSettingsActions.APP_SETTINGS_INIT({
          languageId: appSettingsObj.languageId,
          ctaLanguageId: appSettingsObj.ctaLanguageId,
          countryCode: appSettingsObj.countryCode,
          arePricesAvailable: appSettingsObj.arePricesAvailable,
          showPrices: appSettingsObj.showPrices || false,
          ephemeralDeviceId: appSettingsObj.ephemeralDeviceId,
          isScreenCastActive: appSettingsObj.isScreenCastActive || false,
          streamingAvailable: appSettingsObj.streamingAvailable,
          protocol: appSettingsObj.protocol,
          host: appSettingsObj.host,
          hostName: appSettingsObj.hostName
        })
      );

      if (typeof window.adobeDataLayer != 'undefined') {
        window.adobeDataLayer.push({
          "data": {
            "category": {
              "pageType": "shopping-tools"
            },
            "pageInfo": {
              "countryCode": this.countryCode,
              "language": this.languageId,
              "pageName": "shopping-tools:configurator",
              "vehicleBrand": "mxe",
              "tier": "tier-3"
            }
          }
        });
      }
    }
  }

  private loadCarGridFromLocalStorage() {
    const carGrid = localStorage.getItem('carGrid');
    if (carGrid && this.isJsonString(carGrid)) {
      this.store.dispatch(
        CAR_GRID_LOADED({
          carGrid: JSON.parse(carGrid),
        })
      );
    }
  }

  private loadConfigurationFromLocalStorage() {
    const configuration = localStorage.getItem('config');
    if (configuration && this.isJsonString(configuration)) {
      this.store.dispatch(
        CONFIGURATION_LOADED({
          config: JSON.parse(configuration),
        })
      );
    }
  }

  private loadCountryPropFromLocalStorage() {
    const ctas = localStorage.getItem('ctas')
    if (ctas && this.isJsonString(ctas)) {
      this.store.dispatch(
        CTAS_LOADED({
          ctas: JSON.parse(ctas),
        })
      );
    }

    const labels = localStorage.getItem('labels')
    if (labels && this.isJsonString(labels)) {
      const labelsParsed = JSON.parse(labels)
      this.store.dispatch(
        LABELS_LOADED({
          labels: labelsParsed
        })
      )
      this.uiCommonService.initializeLabels(labelsParsed)
    }
  }

  private loadInterfaceFromLocalStorage() {
    const interfaceLoaded = localStorage.getItem('interface');
    if (interfaceLoaded && this.isJsonString(interfaceLoaded)) {
      this.store.dispatch(
        INTERFACE_LOADED({
          interface: JSON.parse(interfaceLoaded),
        })
      );
    }
  }

  private loadModelsFromLocalStorage() {
    const families = localStorage.getItem('families');
    if (families && this.isJsonString(families)) {
      this.store.dispatch(
        ModelActions.MODELS_LOADED({
          families: JSON.parse(families),
        })
      );
    }

    const optionsByPackages = localStorage.getItem('packages') || ''
    const optionsString = localStorage.getItem('options') || ''
    const hiddenOptionsString = localStorage.getItem('hiddenOptions') || ''
    const defaultOptionsString = localStorage.getItem('defaultOptions') || ''
    let packages
    if (this.isJsonString(optionsByPackages)) {
      packages = JSON.parse(optionsByPackages)
    } else {
      packages = []
    }
    let options
    if (this.isJsonString(optionsString)) {
      options = JSON.parse(optionsString)
    } else {
      options = []
    }
    let hiddenOptions
    if (this.isJsonString(hiddenOptionsString)) {
      hiddenOptions = JSON.parse(hiddenOptionsString)
    } else {
      hiddenOptions = []
    }
    let defaultOptions
    if (this.isJsonString(defaultOptionsString)) {
      defaultOptions = JSON.parse(defaultOptionsString)
    } else {
      defaultOptions = []
    }
    const optionsForAnalytics = localStorage.getItem('optionsForAnalytics') || ''
    this.store.dispatch(
      ModelActions.MODEL_OPTIONS_PARSED({
        packages: packages,
        options: options,
        hiddenOptions: hiddenOptions,
        optionsForAnalytics: optionsForAnalytics,
        defaultOptions: defaultOptions
      })
    );

    let currentCarModel = localStorage.getItem('currentCarModel');
    if (currentCarModel && this.isJsonString(currentCarModel)) {
      this.store.dispatch(ModelActions.NEW_MODEL_SELECTED({
        currentCarModel: JSON.parse(currentCarModel)
      }))
    }
  }

  /**
   * Retrieves the UserState from localstorage
   */
  private loadUserStateFromLocalStorage() {
    let us = localStorage.getItem('user_state');
    if (us && this.isJsonString(us)) {
      let userState = JSON.parse(us) as IUserState;
      if (new Date().getTime() < userState.jwt_token_expiration_time * 1000) {
        if (userState.jwt_token && userState.authorization_code)
          this.store.dispatch(
            UserActions.SET_AUTHORIZATION_CODE({ jwt_token: userState.jwt_token, jwt_token_expiration_time: userState.jwt_token_expiration_time, authorization_code: userState.authorization_code })
          );
        if (userState.dealerId) {
          this.store.dispatch(
            UserActions.SET_DEALER_ID({ dealerId: userState.dealerId })
          );
        }
        if (userState.access_token && userState.access_token_expiration_time > 0) {
          this.store.dispatch(UserActions.SET_ACCESS_TOKEN({ access_token: userState.access_token, access_token_expiration_time: userState.access_token_expiration_time }))
        }
        if (userState.dealersInfo && userState.dealersInfo.length > 0) {
          this.store.dispatch(UserActions.SET_DEALERS_INFO({ dealersInfo: userState.dealersInfo }))
        }
        if (userState.machinesInfo && userState.machinesInfo.length > 0) {
          this.store.dispatch(UserActions.SET_MACHINES_INFO({ machinesInfo: userState.machinesInfo }))
        }
      }
    }
  }

  private loadModels() {
    const payload: GetModelsRequest = {
      country: `${this.countryCode}`,
      lang: this.languageId,
      onlyActive: true
    };
    //this call retrieves all models from service and fill the models state in the store
    this.maseratiService.getModels(payload).subscribe({
      next: (response) => {
        if (!environment.production) {
          console.debug(response);
        }
        console.debug('[MaseratiService] - Models loaded. Family created. Getting common labels...')
        //Preload Labels using the first enabled model 
        let labels = {}
        let ctas = {}
        const models = response.countries[0].models.filter((m) =>
          enabledCarFamilies.familyCommercialCodes.includes(m.familyCommercialCode.toLocaleLowerCase())
        );
        if(!models || models.length == 0 ) {
          console.debug('[MaseratiService] - There are no available models in MXE for this country...')
          return;
        }
        let m = models[0];
        const modelCodes = m.modelCodes.filter(c => c.active_mxe === true)
        if (modelCodes && modelCodes.length > 0) {
          const countryPropRequest: GetCountryPropRequest = {
            country: +payload.country,
            language: payload.lang,
            modelCode: modelCodes[0].modelCode
          }
          this.maseratiService.getCountryProp(countryPropRequest).subscribe(
            {
              next: result => {
                labels[modelCodes[0].modelCode] = this.uiCommonService.updateLanguageIdsTranslation(result.labels, result.languages)
                if (this.ctaLanguageId == payload.lang) {
                  ctas[modelCodes[0].modelCode] = Object.keys(result.actions).map(a => this.uiCommonService.formatAction(a, result.actions[a])).filter(a => a.enabled === true)
                }
              },
              error: (err) => {
                if (!environment.production) {
                  console.error(err)
                }
              },
              complete: () => {
                console.debug(`[MaseratiService] - Labels and actions first initialization.`)
                this.store.dispatch(CTAS_LOADED({ ctas: ctas }))
                this.store.dispatch(LABELS_LOADED({ labels: labels }))
                this.uiCommonService.initializeLabels(labels)
              }
            }
          )
          if (this.ctaLanguageId != payload.lang) {
            //Call countryProp only for ctas in case language of cta is different from the language chosen
            const countryPropRequest: GetCountryPropRequest = {
              country: +payload.country,
              language: this.ctaLanguageId,
              modelCode: modelCodes[0].modelCode
            }
            this.maseratiService.getCountryProp(countryPropRequest).subscribe(
              {
                next: result => {
                  if (result && result.actions) {
                    ctas[modelCodes[0].modelCode] = Object.keys(result.actions).map(a => this.uiCommonService.formatAction(a, result.actions[a])).filter(a => a.enabled === true)
                  }
                },
                error: (err) => {
                  if (!environment.production) {
                    console.error(err)
                  }
                },
                complete: () => {
                  console.debug(`[MaseratiService] - Actions first initialization.`)
                  this.store.dispatch(CTAS_LOADED({ ctas: ctas }))
                }
              }
            )
          }
        } else {
          console.debug('[MaseratiService] no models flagged as active_mxe for this country...')
        }
      },
      complete: () => {
        console.info('[MASERATI SERVICE] getModelCountry call completed')
      },
      error: (e) => {
        if (!environment.production) {
          console.error(e)
        }
      }
    });
  }


  private isJsonString(str): boolean {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
}
