<div class="mxe__app-wrapper">
  <img class="logo-maserati" data-linktracking="top-nav:logo" src="../../../assets/logo-maserati-icon.svg" alt="Maserati" />
  <button type="button" class="btn burger-menu"  data-linktracking ="side-nav:preferences:open" (click)="showHomeMenu()">
    <span class="bar1"></span>
    <span class="bar2"></span>
    <span class="bar3"></span>
  </button>
  <div class="streaming-mode-container" [ngClass]="{'disappear':disappearConnectionModeMessage}" *ngIf="showConnectionModeMessage">
    <div class="streaming-mode-infos">
      <img class="onpremise" src="../../../assets/icons/mxe-ico_on-premise.svg">
      <span class="text-infos">{{ getLabel('MXE_STREAMING_MODE_INFO','You are using') }}&nbsp;<strong>{{getLabel('MXE_STREAMING_MODE_ON_PREMISE_NAME', 'ON PREMISE MODE')}}</strong></span>
    </div>
  </div>

  <mxe-pairing-toggle
    [isMainScreen]="isMainScreen"
    [priceStatus]="priceStatus"
    [pairingStep]="PairingStepEnum.HOME"
    [labels]="labels"
    (screenCastActiveEmitter)="($event)"
    (mirroringStoppedByUserEmitter)="mirroringStoppedByUser = $event">
  </mxe-pairing-toggle>

  <div class="mxe__details-container">
      <div class="title-animated"></div>
  </div>
  <div class="mxe__buttons-container">
   <!-- Per modifica su home aggiungere classe btn-accent al botton sotto -->
    <button type="button" class="btn btn-accent btn-bordered"  data-linktracking ="cta:homepage:new-configuration" (click)="newConfiguration()">
      {{getLabel('MXE_NEW_CONFIGURATION', 'new configuration')}}
    </button>
    <button type="button" class="btn btn-transparent btn-bordered" data-linktracking ="cta:homepage:load-configuration"  (click)="loadConfiguration()">
      {{getLabel('MXE_LOAD_RESTORE_CONFIGURATION','LOAD/RESTORE CONFIGURATION')}}
    </button>
    <button *ngIf="showSni" type="button" class="btn btn-transparent btn-bordered" data-linktracking ="cta:homepage:stock-availability"  (click)="openInStockAvailability()">
      {{getLabel('MXE_IN_STOCK_AVAILABILITY','IN-STOCK AVAILABILITY')}}
    </button>
  </div>

  <div class="outer-sniiframe" *ngIf="showStockAvailability" (click)="closeInStockAvailability()"></div>
  <div *ngIf="showStockAvailability" class="sni-popup">
    <div class="sni-popup-header">
      <span class="sni-popup-close" (click)="closeInStockAvailability()">&#10005;</span>
    </div>
    <div class="sni-popup-content">
      <iframe title="inStockAvailability" *ngIf="stockUrlWithParams" #iframe width="1020px" height="760px" id="instockFrame" [src]="stockUrlWithParams" frameborder="0"></iframe>
    </div>
  </div>
</div>

<app-home-menu
  *ngIf="homeMenuIsOpen"
  (homeMenuStatusChange)="homeMenuStatusController($event)">
</app-home-menu>