import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from '@sentry/angular-ivy'

if (environment.production) {
  enableProdMode();
}
(window as any).contextEnvironment = environment

Sentry.init({
  dsn: environment.sentry_configuration.dsn,
  debug: environment.sentry_configuration.debug,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      maskAllInputs: true
    }),
    Sentry.replayCanvasIntegration()
  ],
  beforeSend(event) {
    const culprit = event.exception?.values?.[0]?.stacktrace?.frames?.[0]?.filename || '';
    if (culprit && !culprit.includes(window.location.origin)) { //Excluding error from third parties domain
      console.log('[SENTRY]: error excluded ', culprit);
      return null;
    }
    return event;
  },
  environment: environment.sentry_configuration.environment,
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: environment.sentry_configuration.tracePropagationTargets,
  // Session Replay
  replaysSessionSampleRate: environment.sentry_configuration.replaysSessionSampleRate, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: environment.sentry_configuration.replaysOnErrorSampleRate, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch( e => console.log ("Error during creation of app instance by bootstrapModule"));
